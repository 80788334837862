import { createSlice } from "@reduxjs/toolkit";

type DatePickerFilterState = {
  tabValues: any;
};

const initialState = {
  tabValues: {},
} as DatePickerFilterState;

export const datePickerFilterSlice = createSlice({
  name: "DatePickerFilter",
  initialState,
  reducers: {
    setTabValueAction(state, action) {
      const { type, value, saveDate } = action.payload;
      state.tabValues[type] = { value, saveDate };
    },
    resetTabValueAction(state, action) {
      const { type } = action.payload;
      state.tabValues[type] = { value: null, saveDate: null };
    },
  },
});

export const { setTabValueAction, resetTabValueAction } = datePickerFilterSlice.actions;
export default datePickerFilterSlice.reducer;
