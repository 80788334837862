import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type IsloginState = {
  isLogin: boolean;
  isLogout: boolean;
};

const initialState = {
  isLogin: false,
  isLogout: false,
} as IsloginState;

export const isLoginSlice = createSlice({
  name: "IsLogin",
  initialState,
  reducers: {
    isLoginAction: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
    isLogoutAction: (state, action: PayloadAction<boolean>) => {
      state.isLogout = action.payload;
    },

    //example for initial state
    // startCount: (state) => {
    //   state.count = 5;
    // },
  },
});

export const { isLoginAction, isLogoutAction } = isLoginSlice.actions;
export default isLoginSlice.reducer;
