import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type PresenceState = {
  dataPresenceAll: any;
  dataCamera: any;
  dataCameraFile: any;
  dataWorkArea: any;
  dataRadiusArea: any;
  dataLatitude: any;
  dataLongitude: any;
  dataCount: any;
  dataCheckDate: any;
  dataTimezone: any;
};

const initialState = {
  dataPresenceAll: null,
  dataCamera: null,
  dataCameraFile: null,
  dataWorkArea: [],
  dataRadiusArea: null,
  dataLatitude: null,
  dataLongitude: null,
  dataCount: null,
  dataCheckDate: null,
  dataTimezone: null,
} as PresenceState;

export const presenceSlice = createSlice({
  name: "Presence",
  initialState,
  reducers: {
    dataPresenceAllAction: (state, action: PayloadAction<any>) => {
      state.dataPresenceAll = action.payload;
    },
    dataCameraAction: (state, action: PayloadAction<any>) => {
      state.dataCamera = action.payload;
    },
    dataCameraFileAction: (state, action: PayloadAction<any>) => {
      state.dataCameraFile = action.payload;
    },
    dataWorkAreaAction: (state, action: PayloadAction<any>) => {
      state.dataWorkArea = action.payload;
    },
    dataRadiusAreaAction: (state, action: PayloadAction<any>) => {
      state.dataRadiusArea = action.payload;
    },
    dataLatitudeAction: (state, action: PayloadAction<any>) => {
      state.dataLatitude = action.payload;
    },
    dataLongitudeAction: (state, action: PayloadAction<any>) => {
      state.dataLongitude = action.payload;
    },
    dataCountAction: (state, action: PayloadAction<any>) => {
      state.dataCount = action.payload;
    },
    dataCheckDateAction: (state, action: PayloadAction<any>) => {
      state.dataCheckDate = action.payload;
    },
    dataTimezoneAction: (state, action: PayloadAction<any>) => {
      state.dataTimezone = action.payload;
    },
  },
});

export const {
  dataTimezoneAction,
  dataCountAction,
  dataPresenceAllAction,
  dataCameraAction,
  dataCameraFileAction,
  dataWorkAreaAction,
  dataRadiusAreaAction,
  dataLatitudeAction,
  dataLongitudeAction,
  dataCheckDateAction,
} = presenceSlice.actions;
export default presenceSlice.reducer;
