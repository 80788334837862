import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ApprovalState = {
  isRecentlyFiltered: boolean;
  isBackdateFiltered: boolean;
  isSpbFiltered: boolean;
  isMtcFiltered: boolean;
  isFilterBackdate: [number, number];
  isFilterSpb: [number, number];
  isFilterMtc: [number, number];
  saveApprovalId: any[];
  filterSalesPrice: [number, number];
  isFilterSalesPrice: boolean;
  isLoadingSalesPrice: boolean;
};

const initialState = {
  isRecentlyFiltered: false,
  isBackdateFiltered: false,
  isSpbFiltered: false,
  isMtcFiltered: false,
  isFilterBackdate: [0, 0],
  isFilterSpb: [0, 0],
  isFilterMtc: [0, 0],
  saveApprovalId: [],
  filterSalesPrice: [0, 0],
  isFilterSalesPrice: false,
  isLoadingSalesPrice: false,
} as ApprovalState;

export const approvalSlice = createSlice({
  name: "Approval",
  initialState,
  reducers: {
    isRecentlyFilteredAction: (state, action: PayloadAction<any>) => {
      state.isRecentlyFiltered = action.payload;
    },
    isBackdateFilteredAction: (state, action: PayloadAction<any>) => {
      state.isBackdateFiltered = action.payload;
    },
    isSpbFilteredAction: (state, action: PayloadAction<any>) => {
      state.isSpbFiltered = action.payload;
    },
    isMtcFilteredAction: (state, action: PayloadAction<any>) => {
      state.isSpbFiltered = action.payload;
    },
    isFilterBackdateAction: (state, action: PayloadAction<any>) => {
      state.isFilterBackdate = action.payload;
    },
    isFilterSpbAction: (state, action: PayloadAction<any>) => {
      state.isFilterSpb = action.payload;
    },
    isFilterMtcAction: (state, action: PayloadAction<any>) => {
      state.isFilterSpb = action.payload;
    },
    saveApprovalIdAction: (state, action: PayloadAction<any>) => {
      state.saveApprovalId = action.payload;
    },
    filterSalesPriceAction: (state, action: PayloadAction<any>) => {
      state.filterSalesPrice = action.payload;
    },
    isFilterSalesPriceAction: (state, action: PayloadAction<any>) => {
      state.isFilterSalesPrice = action.payload;
    },
    isLoadingSalesPriceAction: (state, action: PayloadAction<any>) => {
      state.isLoadingSalesPrice = action.payload;
    },
  },
});

export const {
  filterSalesPriceAction,
  isFilterSalesPriceAction,
  isRecentlyFilteredAction,
  isBackdateFilteredAction,
  isSpbFilteredAction,
  isMtcFilteredAction,
  isFilterBackdateAction,
  isFilterSpbAction,
  isFilterMtcAction,
  saveApprovalIdAction,
  isLoadingSalesPriceAction
} = approvalSlice.actions;
export default approvalSlice.reducer;
