import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  cart: [],
  currentInput: {
    requestReference: "",
    customerName: "",
    customerId: "",
    requestBy: "",
    requestDate: "",
  },
  historyData: [],
};

export const vhsSlice = createSlice({
  name: "Vhs",
  initialState,
  reducers: {
    cartAction: (state, action: PayloadAction<any>) => {
      localStorage.setItem("vhsCart", JSON.stringify(action.payload));
      state.cart = action.payload;
    },
    currentInputAction: (state, action: PayloadAction<any>) => {
      // localStorage.setItem("vhsCustDetail", JSON.stringify(action.payload));
      state.currentInput = action.payload;
    },
    historyDataAction: (state, action: PayloadAction<any>) => {
      state.historyData = action.payload;
    },
  },
});

export const { cartAction, currentInputAction, historyDataAction } =
  vhsSlice.actions;
export default vhsSlice.reducer;
