// firebase-config.ts
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBw9AcYrSRjbv3wi1sgOJKa89X_EksQUpc",
  authDomain: "pwa-pushnotification-83236.firebaseapp.com",
  projectId: "pwa-pushnotification-83236",
  storageBucket: "pwa-pushnotification-83236.appspot.com",
  messagingSenderId: "174704977049",
  appId: "1:174704977049:web:2ecafb14f221e25c67c408",
  measurementId: "G-BFPSM4M4N0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { app, messaging };
