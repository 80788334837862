import { lazy } from "react";

const DetailBookRoom = lazy(() => import("../interface/pages/book-meeting/DetailBookRoom"));
const BookMeetingPage = lazy(() => import("../interface/pages/book-meeting/BookMeetingPage"));
const DetailLineItem = lazy(() => import("../interface/pages/approval/sales-price/DetailLineItem"));
const DetailSalesPriceApproval = lazy(() => import("../interface/pages/approval/sales-price/DetailSalesPriceApproval"));
const SalesPriceApproval = lazy(() => import("../interface/pages/approval/sales-price/SalesPriceApproval"));
const ListSalesPriceApproval = lazy(() => import("../interface/pages/approval/sales-price/ListSalesPriceApproval"));
const Home = lazy(() => import("../interface/pages/home/Home"));
const Notes = lazy(() => import("../interface/pages/note/Notes"));
const NotificationPage = lazy(() => import("../interface/pages/notification/NotificationPage"));
const Profile = lazy(() => import("../interface/pages/profile/Profile"));
const EditProfileForm = lazy(() => import("../interface/pages/profile/EditProfileForm"));
const ArticleList = lazy(() => import("../interface/pages/article/ArticleList"));
const DetailArticle = lazy(() => import("../interface/pages/article/DetailArticle"));
const Presence = lazy(() => import("../interface/pages/presence/Presence"));
const DetailPresence = lazy(() => import("../interface/pages/presence/DetailPresence"));
const HistoryPresence = lazy(() => import("../interface/pages/presence/HistoryPresence"));
const CameraMaps = lazy(() => import("../interface/pages/presence/CameraMaps"));
const CreatePresence = lazy(() => import("../interface/pages/presence/CreatePresence"));
const PresenceQR = lazy(() => import("../interface/pages/presence/PresenceQR"));
const PageSuccess = lazy(() => import("../interface/pages/presence/PageSuccess"));
const ApprovalPage = lazy(() => import("../interface/pages/approval/ApprovalPage"));
const DetailBackdateApproval = lazy(() => import("../interface/pages/approval/backdate-approval/DetailBackdateApproval"));
const DetailSpbApproval = lazy(() => import("../interface/pages/approval/spb-approval/DetailSpbApproval"));
const MtcPage = lazy(() => import("../interface/pages/mtc/MtcPage"));
const CreateMtc = lazy(() => import("../interface/pages/mtc/CreateMtc"));
const CalendarMtc = lazy(() => import("../interface/pages/mtc/CalendarMtc"));
const HistoryMtc = lazy(() => import("../interface/pages/mtc/HistoryMtc"));
const DetailMtc = lazy(() => import("../interface/pages/mtc/DetailMtc"));
const VHSDashboard = lazy(() => import("../interface/pages/vhs/TheDashboard"));
const VHSScan = lazy(() => import("../interface/pages/vhs/VhsScan"));
const VHSCart = lazy(() => import("../interface/pages/vhs/VhsCart"));
const HistoryVhs = lazy(() => import("../interface/pages/vhs/HistoryVhs"));
const VHSDetail = lazy(() => import("../interface/pages/vhs/VhsDetail"));
const MtcApproval = lazy(() => import("../interface/pages/approval/mtc-approval/MtcApproval"));
const BackDateApproval = lazy(() => import("../interface/pages/approval/backdate-approval/BackdateApproval"));
const SpbApproval = lazy(() => import("../interface/pages/approval/spb-approval/SpbApproval"));
const PostingMtcApproval = lazy(() => import("../interface/pages/approval/mtc-approval/posting-mtc/PostingMtcApproval"));
const ConfirmMtcPosting = lazy(() => import("../interface/pages/approval/mtc-approval/posting-mtc/ConfirmMtcPosting"));
const ResponseMtcPosting = lazy(() => import("../interface/pages/approval/mtc-approval/posting-mtc/ResponseMtcPosting"));
const CancelMtcApproval = lazy(() => import("../interface/pages/approval/mtc-approval/cancel-mtc/CancelMtcApproval"));
const ConfirmMtcCancel = lazy(() => import("../interface/pages/approval/mtc-approval/cancel-mtc/ConfirmMtcCancel"));

export const routing = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/notes",
    element: <Notes />,
  },
  {
    path: "/notification",
    element: <NotificationPage />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/edit-profile",
    element: <EditProfileForm />,
  },
  {
    path: "/article-list",
    element: <ArticleList />,
  },
  {
    path: "/article-detail/:id",
    element: <DetailArticle />,
  },
  {
    path: "/presence",
    element: <Presence />,
  },
  {
    path: "/detail-presence",
    element: <DetailPresence />,
  },
  {
    path: "/presence-history",
    element: <HistoryPresence />,
  },
  {
    path: "/map-presence",
    element: <CameraMaps />,
  },
  {
    path: "/create-presence",
    element: <CreatePresence />,
  },
  {
    path: "/qr-code-scanner",
    element: <PresenceQR />,
  },
  {
    path: "/page-success",
    element: <PageSuccess />,
  },
  {
    path: "/approval-page",
    element: <ApprovalPage />,
  },
  {
    path: "/detail-backdate-approval/:id",
    element: <DetailBackdateApproval />,
  },
  {
    path: "/detail-spb-approval/:id",
    element: <DetailSpbApproval />,
  },
  {
    path: "/mtc-page",
    element: <MtcPage />,
  },
  {
    path: "/mtc-create",
    element: <CreateMtc />,
  },
  {
    path: "/mtc-calendar",
    element: <CalendarMtc />,
  },
  {
    path: "/mtc-history",
    element: <HistoryMtc />,
  },
  {
    path: "/mtc-detail/:id",
    element: <DetailMtc />,
  },
  {
    path: "/vhs/dashboard",
    element: <VHSDashboard />,
  },
  {
    path: "/vhs/scan",
    element: <VHSScan />,
  },
  {
    path: "/vhs/cart",
    element: <VHSCart />,
  },
  {
    path: "/vhs/history",
    element: <HistoryVhs />,
  },
  {
    path: "/vhs/:id",
    element: <VHSDetail />,
  },
  {
    path: "/backdate-approval",
    element: <BackDateApproval />,
  },
  {
    path: "/spb-approval",
    element: <SpbApproval />,
  },
  {
    path: "/mtc-approval",
    element: <MtcApproval />,
  },
  {
    path: "/posting-mtc-approval",
    element: <PostingMtcApproval />,
  },
  {
    path: "/cancel-mtc-approval",
    element: <CancelMtcApproval />,
  },
  {
    path: "/confirm-cancel-mtc",
    element: <ConfirmMtcCancel />,
  },
  {
    path: "/confirm-posting-mtc",
    element: <ConfirmMtcPosting />,
  },
  {
    path: "/response-posting-mtc",
    element: <ResponseMtcPosting />,
  },
  {
    path: "/approval-sales-price",
    element: <SalesPriceApproval />,
  },
  {
    path: "/list-sales-price-approval/:args",
    element: <ListSalesPriceApproval />,
  },
  {
    path: "/detail-sales-price-approval/:id",
    element: <DetailSalesPriceApproval />,
  },
  {
    path: "/detail-line-item/:id",
    element: <DetailLineItem />,
  },
  {
    path: "/book-meeting",
    element: <BookMeetingPage />,
  },
  {
    path: "/detail-book-room/:id",
    element: <DetailBookRoom />,
  },
];
