import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";

type MtcState = {
  isButtonVisibility: boolean;
  mtcMonth: number;
  mtcYear: number;
  workDate: any;
  mtcFilterValue: any;
};

const initialState = {
  isButtonVisibility: true,
  mtcMonth: dayjs().month() + 1,
  mtcYear: dayjs().year(),
  workDate: new Date(),
  mtcFilterValue: [],
} as MtcState;

export const mtcSlice = createSlice({
  name: "Mtc",
  initialState,
  reducers: {
    isButtonVisibilityAction: (state, action: PayloadAction<any>) => {
      state.isButtonVisibility = action.payload;
    },
    mtcMonthAction: (state, action: PayloadAction<any>) => {
      state.mtcMonth = action.payload;
    },
    mtcYearAction: (state, action: PayloadAction<any>) => {
      state.mtcYear = action.payload;
    },
    workDateAction: (state, action: PayloadAction<any>) => {
      state.workDate = action.payload;
    },
    mtcFilterValueAction: (state, action: PayloadAction<any>) => {
      state.mtcFilterValue = action.payload;
    },
  },
});

export const { isButtonVisibilityAction, mtcMonthAction, mtcYearAction, workDateAction, mtcFilterValueAction } = mtcSlice.actions;
export default mtcSlice.reducer;
