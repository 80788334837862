import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import isLoginReducer from "./features/auth/isLogin-slice";
import userProfileReducer from "./features/profile/userProfile-slice";
import presenceReducer from "./features/presence/presence-slice";
import approvalReducer from "./features/approval/approval-slice";
import vhsReducer from "./features/vhs/vhs-slice";
import mtcReducer from "./features/mtc/mtc-slice";
import datePickerFilterReducer from "./features/components/datePickerFilter-slice";

export const store = configureStore({
  reducer: {
    isLoginReducer,
    userProfileReducer,
    presenceReducer,
    approvalReducer,
    vhsReducer,
    mtcReducer,
    datePickerFilterReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
