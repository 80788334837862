import { Result } from "antd";
import { AnimatePresence } from "framer-motion";
import { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PuffLoader } from "react-spinners";
import Colors from "./helpers/Colors";
import { useWindowSize } from "./hooks/useWindowSize";
import { routing } from "./routes/Routing";
import PushNotification from "./components/firebaseNotifications/PushNotification";
import { Toaster } from "react-hot-toast";

const Login = lazy(() => import("./interface/pages/auth/Login"));
const Verify = lazy(() => import("./interface/pages/auth/Verify"));
const VerifyLoginComp = lazy(() => import("./components/VerifyLoginComp"));
const MainLayout = lazy(() => import("./interface/layouts/MainLayout"));
const Page404 = lazy(() => import("./interface/result/Page404"));
const Page403 = lazy(() => import("./interface/result/Page403"));
const Page500 = lazy(() => import("./interface/result/Page500"));
const PageBadRequest = lazy(() => import("./interface/result/PageBadRequest"));
const PageMaintenance = lazy(() => import("./interface/result/PageMaintenance"));
const PageCheckData = lazy(() => import("./interface/result/PageCheckData"));

function App() {
  const [size] = useWindowSize();
  let isMobile = size <= 480;

  if (!isMobile) {
    return (
      <div className="flex flex-col items-center justify-center h-screen p-4">
        <Result status="403" title="Sorry, this app is not available on desktop." subTitle="Please use this app on a mobile device." />
      </div>
    );
  }

  return (
    <Suspense
      fallback={
        <div className="flex items-center justify-center h-screen">
          <PuffLoader color={Colors.primary} />
        </div>
      }
    >
      <BrowserRouter>
        <PushNotification />
        <AnimatePresence mode="wait">
          <Routes>
            {/* Error & Utility Pages */}
            <Route path="*" element={<Page404 />} />
            <Route path="/page403" element={<Page403 />} />
            <Route path="/page500" element={<Page500 />} />
            <Route path="/maintenance" element={<PageMaintenance />} />
            <Route path="/bad-request" element={<PageBadRequest />} />
            <Route path="/check-data-orawt" element={<PageCheckData />} />

            {/* Auth Routes */}
            <Route element={<VerifyLoginComp />}>
              <Route path="/login" element={<Login />} />
              <Route path="/verify" element={<Verify />} />
            </Route>

            {/* Main App Routes */}
            <Route path="/" element={<MainLayout />}>
              {routing.map((item, index) => (
                <Route key={`route-${item.path}-${index}`} path={item.path} element={item.element} />
              ))}
            </Route>
          </Routes>
        </AnimatePresence>
        <Toaster />
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
