export default {
  primary: "#FB7B70",
  secondary: "#9597C8",
  third: "#84CEC1",
  background: "#FAFAFA",
  text: "#444444",
  subtext: "#A29CA3",
  textSecondary: "#8D99AE",
  textWhite: "#FFFFFF",
  textBlack: "#000000",
  textError: "#FF0000",
  textSuccess: "#20D489",
  textWarning: "#FFA500",
  textInfo: "#0000FF",
  textDisabled: "#A9A9A9",
};
