// firebase.ts (folder src)
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "./firebase-config";
import axios from "axios";
import loginApi from "../../api/auth/loginApi";

// Request permission and get token
export const requestForToken = async (): Promise<void> => {
  const vapidKey = `BF1HDPR0w_8h-ZK4m0LPa7eaG51dcmzTzRlBqrzH6lziv7ZLzwwzdjfsvekC_9k-xYezFD2urrOxaurP-3gJIaA`;

  try {
    const currentToken = await getToken(messaging, { vapidKey });
    if (currentToken) {
      try {
        const storedToken = localStorage.getItem("fcmToken");
        if (storedToken !== currentToken) {
          localStorage.setItem("fcmToken", currentToken);
        }

        const token = localStorage.getItem("accessToken");
        if (!token) {
          return;
        }

        const deviceID = localStorage.getItem("deviceID");
        const platform = localStorage.getItem("platform");

        await axios.post(
          loginApi.saveFcmToken,
          { fcm_token: currentToken },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${token}`,
              DeviceId: deviceID,
              PlatformID: platform,
              site: "mobile-site",
            },
          }
        );
      } catch (error) {
        console.error(error);
      }
    } else {
      console.log("No registration token available. Request permission to generate one.");
    }
  } catch (err) {
    console.error("An error occurred while retrieving token. ", err);
  }
};

// Handle incoming messages
export const onMessageListener = (): Promise<unknown> =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
