import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type UserProfileState = {
  dataUser: any;
};

const initialState = {
  dataUser: null,
} as UserProfileState;

export const userProfileSlice = createSlice({
  name: "UserProfile",
  initialState,
  reducers: {
    userProfileAction: (state, action: PayloadAction<boolean>) => {
      state.dataUser = action.payload;
    },
  },
});

export const { userProfileAction } = userProfileSlice.actions;
export default userProfileSlice.reducer;
