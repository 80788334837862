import { ConfigProvider, Modal } from "antd";
import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";
import App from "./App";
import Colors from "./helpers/Colors";
import "./index.css";
import { ReduxProvider } from "./redux/provider";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import PushNotification from "./components/firebaseNotifications/PushNotification";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <ReduxProvider>
    <ConfigProvider theme={{ token: { colorPrimary: Colors.primary } }}>
      <App />
    </ConfigProvider>
  </ReduxProvider>
);

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      Modal.confirm({
        centered: true,
        closable: false,
        maskClosable: false,
        title: "New Version Available",
        content: "A new version is available. Please update to get the latest features and fixes.",
        okText: "Update Now",
        okButtonProps: { style: { backgroundColor: Colors.primary } },
        cancelButtonProps: { style: { display: "none" } },
        onOk: () => {
          if (registration.waiting) {
            registration.waiting.postMessage({ type: "SKIP_WAITING" });
            window.location.reload();
          }
        },
      });
    }
  },
});
