import React, { useState, useEffect } from "react";
import { requestForToken, onMessageListener } from "./firebase";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

interface NotificationPayload {
  title: string;
  body: string;
  data?: {
    click_action?: string;
    link?: string;
    [key: string]: any;
  };
}

const PushNotification: React.FC = () => {
  const [notification, setNotification] = useState<NotificationPayload | null>(null);
  const navigate = useNavigate();

  const handleNotificationClick = (notification: NotificationPayload) => {
    if (notification.data?.link) {
      // Extract path from full URL
      const url = new URL(notification.data.link);
      const path = url.pathname + url.search;
      navigate(path);
    }
  };

  const ToastDisplay = () => (
    <div onClick={() => notification && handleNotificationClick(notification)} style={{ cursor: "pointer" }}>
      <p>
        <b>{notification?.title}</b>
      </p>
      <p>{notification?.body}</p>
    </div>
  );

  const notify = () =>
    toast(<ToastDisplay />, {
      duration: 4000,
      position: "top-right",
    });

  useEffect(() => {
    if (notification?.title) {
      notify();
    }
  }, [notification]);

  useEffect(() => {
    requestForToken();

    onMessageListener()
      .then((payload: any) => {
        setNotification({
          title: payload?.notification?.title,
          body: payload?.notification?.body,
          data: payload?.data,
        });
      })
      .catch((err) => console.log("failed: ", err));

    // Menangani kasus ketika aplikasi dibuka dari notifikasi background
    const handleInitialRoute = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const notificationClick = urlParams.get("NOTIFICATION_CLICK");

      if (notificationClick) {
        // Handle specific routing based on notification data
        const targetPath = urlParams.get("path") || "/";
        navigate(targetPath);
      }
    };

    handleInitialRoute();
  }, [navigate]);

  return null;
};

export default PushNotification;
